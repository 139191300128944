import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { atomDossier } from 'src/storage'

export const useSubHeader = () => {
  const { t } = useTranslation()

  const dossier = useRecoilValue(atomDossier)

  const path = dossier?.prodottoDesc ? `/ ${dossier?.prodottoDesc}` : ''

  return {
    t,
    path,
  }
}

import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { Dossier, InsuranceProposal, PostPolicy, QuestionnaireWrapper, Sede } from 'src/types'
import { FormikModal, FormikPolicy, FormikSedi } from 'src/types/formik'
import { PaymentResponse } from 'src/types/networking/terminals/payment.types'

export const persistAtomDossier = recoilPersist({
  key: 'dossier',
  storage: sessionStorage,
})

export const atomDossier = atom<Dossier>({
  key: 'atomDossier',
  default: undefined,
  effects_UNSTABLE: [persistAtomDossier.persistAtom],
})

export const persistAtomPolicy = recoilPersist({
  key: 'policies',
  storage: sessionStorage,
})

export const atomPolicy = atom<InsuranceProposal>({
  key: 'atomPolicy',
  default: undefined,
  effects_UNSTABLE: [persistAtomPolicy.persistAtom],
})

export const persistAtomPostPolicyResponse = recoilPersist({
  key: 'persistAtomPostPolicyResponse',
  storage: sessionStorage,
})

export const atomPostPolicyResponse = atom<PostPolicy['response']>({
  key: 'atomPostPolicyResponse',
  default: undefined,
  effects_UNSTABLE: [persistAtomPostPolicyResponse.persistAtom],
})

const persistAtomFormik = recoilPersist({
  key: 'formik',
  storage: sessionStorage,
})

export const atomFormikPolicy = atom<FormikPolicy>({
  key: 'atomFormikPolicy',
  default: {
    warranties: [],
    questions: [],
    consentInformativeSet: false,
    consentDigitalTransmission: false,
    consentPrivacy: false,
    consentMarketing: false,
    consentProfiling: false,
    consentPersonalData: false,
    email: '',
    phone: '',
  },
  effects_UNSTABLE: [persistAtomFormik.persistAtom],
})

export const persistAtomFormikSede = recoilPersist({
  key: 'formikSedi',
  storage: sessionStorage,
})

export const atomFormikSede = atom<FormikSedi>({
  key: 'atomFormikSede',
  default: {
    company: '',
  },
  effects_UNSTABLE: [persistAtomFormikSede.persistAtom],
})

export const persistAtomFormikModal = recoilPersist({
  key: 'formikModal',
  storage: sessionStorage,
})

export const atomFormikModal = atom<FormikModal>({
  key: 'atomFormikModal',
  default: {
    email: '',
    confirmMail: '',
    phone: '',
  },
  effects_UNSTABLE: [persistAtomFormikModal.persistAtom],
})

const persistSedi = recoilPersist({
  key: 'selectedSede',
  storage: sessionStorage,
})

export const atomSedi = atom<Sede[]>({
  key: 'atomSedi',
  default: [],
  effects_UNSTABLE: [persistSedi.persistAtom],
})

const persistAtomQuestionnaire = recoilPersist({
  key: 'questionnaire',
  storage: sessionStorage,
})

export const atomQuestionnaire = atom<QuestionnaireWrapper>({
  key: 'atomQuestionnaire',
  default: undefined,
  effects_UNSTABLE: [persistAtomQuestionnaire.persistAtom],
})

export const atomPaymentData = atom<PaymentResponse>({
  key: 'paymentData',
  default: undefined,
})

const persistAtomCurrentStep = recoilPersist({
  key: 'currentStep',
  storage: sessionStorage,
})

export const atomCurrentStep = atom<number>({
  key: 'atomCurrentStep',
  default: 1,
  effects_UNSTABLE: [persistAtomCurrentStep.persistAtom],
})

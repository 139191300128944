import { MOCKEND_ALL_DISABLED, MOCKEND_TIMING } from 'src/config'
import { MockendConfig } from 'src/types'

import getCookie from './mocks/getCookie.json'
import getDocument from './mocks/getDocument.json'
import getDossier from './mocks/getDossier.json'
import getIdToken from './mocks/getIdToken.json'
import getPolizza from './mocks/getPolizza.json'
import getQuestionario from './mocks/getQuestionario.json'
import getSedi from './mocks/getSedi.json'
import patchEmail from './mocks/patchEmail.json'
import postPolizza from './mocks/postPolizza.json'
import postQuestionario from './mocks/postQuestionario.json'
import putPolizza from './mocks/putPolizza.json'

export const ALL_DISABLED = MOCKEND_ALL_DISABLED
export const ALL_ENABLED = true
export const TIMING = MOCKEND_TIMING

export const CONFIG: MockendConfig = {
  getCookie: {
    enabled: true,
    status: 200,
    mocks: {
      200: getCookie,
    },
  },
  getDossier: {
    enabled: true,
    status: 200,
    mocks: {
      200: getDossier,
    },
  },
  getSedi: {
    enabled: true,
    status: 200,
    mocks: {
      200: getSedi,
    },
  },
  getPolicy: {
    enabled: true,
    status: 200,
    mocks: {
      200: getPolizza,
    },
  },
  putPolicy: {
    enabled: true,
    status: 200,
    mocks: {
      200: putPolizza,
    },
  },
  postPolicy: {
    enabled: true,
    status: 200,
    mocks: {
      200: postPolizza,
    },
  },
  getQuestionnaire: {
    enabled: true,
    status: 200,
    mocks: {
      200: getQuestionario,
    },
  },
  postQuestionnaire: {
    enabled: true,
    status: 200,
    mocks: {
      200: postQuestionario,
    },
  },
  patchEmail: {
    enabled: true,
    status: 200,
    mocks: {
      200: patchEmail,
    },
  },
  getIdToken: {
    enabled: true,
    status: 200,
    mocks: {
      200: getIdToken,
    },
  },
  getDocument: {
    enabled: false,
    status: 200,
    mocks: {
      200: getDocument,
    },
  },
}

import { typedMemo } from 'src/types'

import { useSubHeader } from './sub-header.hook'

export const SubHeader = typedMemo(() => {
  const { t, path } = useSubHeader()

  return (
    <div className="p-4 p-md-5">
      <div className="c-939393 font-15 text-uppercase">{`${t('subheader.root')} ${path}`}</div>
    </div>
  )
})

import './wrapper.root.scss'

import { ReactNode } from 'react'
import { Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Paths } from 'src/navigation'
import { Footer, Header, SubHeader } from 'src/ui/fragments'

export const WrapperRoot = ({ children }: { children: ReactNode }) => {
  const location = useLocation()

  return (
    <Card>
      <div className="wrapper--header">
        <Header />
      </div>
      {location.pathname === Paths.Landing && (
        <div className="wrapper--sub-header">
          <SubHeader />
        </div>
      )}
      <div className="wrapper--body">{children}</div>
      <div className="layout--footer">{<Footer />}</div>
    </Card>
  )
}

import { Route } from 'src/types/navigation/routing'
import {
  Error404,
  GenericError,
  Landing,
  PaymentFailure,
  PaymentSuccess,
  PolicyConfiguration,
} from 'src/ui/pages'
import { CookiePolicy } from 'src/ui/pages/cookie-policy/cookie-policy'
import { ProgressProvider } from 'src/ui/pages/policy-configuration/sections/progress-provider/progress-provider'

export const Paths = {
  Landing: '/',
  Error404: '*',
  Accessibility: '/accessibilita',
  PolicyConfiguration: '/configurazione-polizza',
  PaymentSuccess: '/esito-pagamento-ok',
  PaymentFailure: '/esito-pagamento-ko',
  GenericError: '/errore',
  CookiePolicy: '/cookie-policy',
}

export const Routes: Record<keyof typeof Paths, Route> = {
  Landing: {
    path: Paths.Landing,
    element: (
      <ProgressProvider>
        <Landing />
      </ProgressProvider>
    ),
    private: true,
  },
  Error404: {
    path: Paths.Error404,
    element: <Error404 />,
  },
  Accessibility: {
    path: Paths.Accessibility,
    element: <div>TODO: Accessibilità</div>,
  },
  CookiePolicy: {
    path: Paths.CookiePolicy,
    element: <CookiePolicy />,
  },
  PolicyConfiguration: {
    path: Paths.PolicyConfiguration,
    element: (
      <ProgressProvider>
        <PolicyConfiguration />
      </ProgressProvider>
    ),
  },
  PaymentSuccess: {
    path: Paths.PaymentSuccess,
    element: <PaymentSuccess />,
  },
  PaymentFailure: {
    path: Paths.PaymentFailure,
    element: <PaymentFailure />,
  },
  GenericError: {
    path: Paths.GenericError,
    element: <GenericError />,
  },
}

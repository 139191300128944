import Cookies from 'js-cookie'
import { useAws } from 'src/networking/aws'
import { AwsGateCallbacks, AwsGateRequest, GetDossier } from 'src/types'

export const useGetDossier = <
  T extends GetDossier['request'],
  D extends GetDossier['response']
>() => {
  const hook = useAws<T, D>('getDossier')

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
      }
      return hook.crossGate(
        {
          method: 'get',
          path: `/dossier`,
          ...request,
        },
        callbacks
      )
    },
  }
}

import React from 'react'
import { AnyObjectSchema } from 'yup'

import { Formik, FormikPolicy } from './formik'
import { Warranty } from './networking'

export type Optional<T> = T | undefined

export type Voidable<T> = T | ''

export type Callbacks<T = () => void> = Record<string, T>

export const typedMemo: <T>(c: T) => T = React.memo

export interface PolicyCardProps {
  name: string
  isSelected?: boolean
  touched: boolean | undefined
  errors: string | undefined
  setFieldValue?: Formik['setFieldValue']
  validationSchema?: AnyObjectSchema
  policy: Warranty | undefined
  maximumId: string | undefined
  disabled?: boolean
  addRemovePolicy: (warrantyId?: number, name?: string | undefined, operationType?: string) => void
}

export interface PolicyConfigurationProps {
  formik: Formik<FormikPolicy>
  validationSchema: AnyObjectSchema
}

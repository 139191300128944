import { selector } from 'recoil'
import { Address, Carousel, Document, Faq, Insured, Optional } from 'src/types'

import { atomDossier, atomPolicy } from './data.atoms'

export const selectorFaqs = selector<Optional<Faq[]>>({
  key: 'faq',
  get: ({ get }) => get(atomDossier)?.faq,
})

export const selectorDocuments = selector<Optional<Document[]>>({
  key: 'documents',
  get: ({ get }) => get(atomDossier)?.documenti.filter((doc) => doc.docCode.startsWith('DOC')),
})

export const selectorFooterDocuments = selector<Optional<Document[]>>({
  key: 'footerDocuments',
  get: ({ get }) => get(atomDossier)?.documenti.filter((doc) => doc.docCode.startsWith('FOOTER')),
})

export const selectorConsentDocuments = selector<Optional<Document[]>>({
  key: 'consentDocuments',
  get: ({ get }) => get(atomDossier)?.documenti.filter((doc) => doc.docCode.startsWith('CONS')),
})

export const selectorCarousel = selector<Optional<Carousel[]>>({
  key: 'carousel',
  get: ({ get }) => get(atomDossier)?.carousel,
})

export const selectorInsured = selector<Optional<Insured>>({
  key: 'insured',
  get: ({ get }) => get(atomPolicy)?.assicurato,
})

export const selectorAddress = selector<Optional<Address>>({
  key: 'office',
  get: ({ get }) => get(atomPolicy)?.sede,
})
